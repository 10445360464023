<template>
  <div class="container" style="background-color: white;">
    <div class="row">
      <table style="font-size: 14px;">
        <tr>
          <td>
            <img src="@/assets/images/logo.png" style="width: 35px;"> &nbsp
          </td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td width="5%" style="display: flex;">
            <h5>whatyouwantshop</h5>
          </td>
          <td style="text-align: left;"></td>
          <td  style="text-align: right">
            <span style="font-size: 14px;color: black">{{ stocks.no_stock }}</span>

          </td>
        </tr>
        <tr>
          <td colspan="3" style="text-align: center">
            <h2>ใบเบิกสินค้า</h2>
          </td>
        </tr>
        <tr style="font-size: 18px;">
          <td style="text-align: left;width: 50% !important;">

          </td>
          <td style="text-align: right;width: 20% !important;">
            <h6><u>รายละเอียด</u></h6>
          </td>
          <td style="text-align: left;width: 30% !important;">
            <br><br><br>
            <br><br><br>
            <h6 style="margin-top: -10px"> เลขที่:
              {{ stocks.no_stock ? stocks.no_stock : '-' }}
            </h6> <br>
            <h6 style="margin-top: -10px"> วันที่: {{ stocks.created_at }}</h6><br>
            <h6 style="margin-top: -10px">
              ชื่อผู้ขอเบิก/พนักงาน: {{ stocks.employee ? stocks.employee.employee_name : '-' }}</h6>
            <br>
          </td>

        </tr>

      </table>
      <br>
      <br>

      <div class="table-responsive-sm" style="width: 100%">
        <table style="width: 100%;">
          <thead>
          <tr style="">
            <th style="text-align: left;width: 30px">ลำดับ</th>
            <th style="width: 80px;"><b>รหัสสินค้า</b></th>
            <th><b>รายการ</b></th>
            <th style="width: 100px;"><b>หน่วย</b></th>
            <th style="text-align: right;width: 100px;"><b>ราคา</b></th>
            <th style="text-align: center;width: 100px;"><b>จำนวนเบิก</b></th>
            <th style="text-align: right;width: 200px;"><b>มูลค่า(บาท)</b></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(stock_employee,index) in stock_employees" aria-rowindex="1">
            <td> {{ index + 1 }}</td>
            <td> {{ stock_employee.product ? stock_employee.product.product_code : '-' }}</td>
            <td> {{ stock_employee.product ? stock_employee.product.product_name : "-" }}</td>
            <td style="text-align: center">ชิ้น</td>
            <td style="text-align: right">
              {{ stock_employee.product ? stock_employee.product.product_costs : '' }}
              <!--            @if(-->
              <!--            isset(auth()->user()->role()['all']) && auth()->user()->role()['all'] ||-->
              <!--            isset(auth()->user()->role()['view_product_price']) && auth()->user()->role()['view_product_price'])-->
              <!--            {{$stock_employee->product_costs}}-->
              <!--            @else-->
              <!--            - -->
              <!--            @endif-->
            </td>

            <td style="text-align: center">
              {{ stock_employee.amount ? stock_employee.amount : '-' }}
            </td>
            <!--          <?php $amount += $stock_employee->amount;?>-->
            <!--          <?php $total += (int) $stock_employee->product_costs * (int)$stock_employee->amount;?>-->
            <td style="text-align: right">
              {{
                stock_employee.product ? stock_employee.product.product_costs * stock_employee.amount : 0
              }}
              <!--            @if(-->
              <!--            isset(auth()->user()->role()['all']) && auth()->user()->role()['all'] ||-->
              <!--            isset(auth()->user()->role()['view_product_price']) && auth()->user()->role()['view_product_price'])-->
              <!--            {{(int) $stock_employee->product_costs * (int)$stock_employee->amount }}-->
              <!--            @else-->
              <!--            - -->
              <!--            @endif-->
            </td>
          </tr>
          <tr role="row">
            <td style="text-align:left" colspan="5"></td>
          </tr>
          <tr role="row">
            <td style="text-align:left" colspan="5"></td>
          </tr>
          <tr role="row">
            <td style="text-align:left" colspan="4"></td>
            <td style="text-align:right"><h4><b>รวม </b></h4></td>
            <td style="text-align:center;  "><h4>{{ getAmount() }}</h4></td>
            <td style="text-align:right;  "><h4>
              {{ getTotal() }}
              <!--            @if(-->
              <!--            isset(auth()->user()->role()['all']) && auth()->user()->role()['all'] ||-->
              <!--            isset(auth()->user()->role()['view_product_price']) && auth()->user()->role()['view_product_price'])-->
              <!--            {{$total}}-->
              <!--            @else-->
              <!--            - -->
              <!--            @endif-->
            </h4></td>
          </tr>

          </tbody>
        </table>


        <table style="width: 100%;margin-top: 50%">
          <tr>
            <td style="text-align: left;width:45%">
              ลายชื่อผู้ขอเบิก: -------------------<br>

            </td>

            <td style="text-align: right;width:45%">
              ลายชื่อผู้ดำเนินการ/พนักงาน: -------------------<br>
            </td>
          </tr>

        </table>

      </div>
    </div>
  </div>
</template>

<script>
import {mdiPencil, mdiDelete} from '@mdi/js'
// import config from '../../../config'
// import instance from '../../../axios.service'
import Swal from "sweetalert2";
import {email, minLength, required} from "@vuelidate/validators";
import {useVuelidate} from "@vuelidate/core";
import instance_purchase_order from "@/services/purchaseOrder";
import instance_payment_type from "@/services/payment";
import instance_stock_employee from "@/services/stockEmokoyee";

export default {
  setup() {
    return {
      v$: useVuelidate(),
      icons: {
        mdiPencil,
        mdiDelete,
      },
    }
  },
  data: () => ({
    type: true ? 'ใบสั่งซื้อสินค้า Purchase Order' : 'ใบสั่งซื้อสินค้า Purchase Order',
    payment_types: [],
    data: {
      'supplier_name': '',
      'employee_name': '',
      'employee_id': '',
      'purchase_on': '',
      'tax_id': '',
      'address': '',
      'date': '',
      'payment_id': 'เลือกการชำระเงิน',
      'contact_name': '',
      'project_name': '',
      'sub_total': 0,
      'discount': 0,
      'total': 0,
      'vat': 0,
      'note': '',
      'is_vat': true,

    },
    stocks: {},
    stock_employees: [],
    configs: [],
    print_envelope_order_details: [],
    width: 100,
    height: 150,
    show: 'Y',
    amount_options: [
      1, 5, 10, 20, 50, 100, 500, 1000
    ],
    amount: 1,
    total: 1,
    selected: [],
    product_options: [],
    count: 0,
    product_select: 'ค้นหาสินค้า',
    purchase_order_type: 1, //ใบสั่งซื้อสินค้า Purchase Order 1    //
    length_for: 0,
    purchase_order_details_length: 7
  }),
  validations() {
    return {
      editedItem: {
        name: {required},
        value: {required},
      }
    }
  },
  computed: {
    selectAll: {
      get: function () {
        return this.purchase_order_details ? this.selected.length == this.purchase_order_details.length : false;
      },
      set: function (value) {
        var selected = [];

        if (value) {
          Object.entries(this.purchase_order_details).forEach(([key, val]) => {
            if (val.product_id) {
              selected.push(parseInt(val.product_id));
            }

          });
        }

        this.selected = selected;
      }
    },
    subTotal: function () {
      let total = [];
      Object.entries(this.purchase_order_details).forEach(([key, val]) => {
        let sum = 0;
        if (val.product_id) {
          let product_costs = val.product_costs;
          let amount = val.amount;
          let discount = parseInt(val.discount);
          if ((product_costs * amount) > discount) {
            sum = (product_costs * amount) - discount
          } else {
            sum = (product_costs * amount)
          }
        }

        total.push(parseFloat(sum).toFixed(2));
      });

      return total.reduce(function (total, num) {
        return parseInt(total) + parseInt(num)
      }, 0);

    },
    afterDiscount: function () {
      let subTotal = this.subTotal;
      let discount = this.data.discount;
      return parseFloat(subTotal - discount).toFixed(2);
    },
    VatTotal: function () {
      let afterDiscount = this.afterDiscount;
      let vat = (afterDiscount * 100) / 107;
      return parseFloat(afterDiscount - vat).toFixed(2);
    },
  },

  watch: {},

  created() {

  },
  mounted() {
    this.data.id = this.$route.params.id;
    this.purchase_order_type = this.$route.query.purchase_order_type;
    this.show = this.$route.query.show;
    this.width = this.$route.query.width;
    this.height = this.$route.query.height;
    this.getStockEmployeeReport();


  },
  methods: {
    getAmount() {
      var amount = 0;
      for (var i = 0; i < this.stock_employees.length; i++) {
        amount += this.stock_employees[i].amount
      }
      return amount
    },
    getTotal() {
      var total = 0;
      for (var i = 0; i < this.stock_employees.length; i++) {
        total += this.stock_employees[i].product ? this.stock_employees[i].product.product_costs * this.stock_employees[i].amount : 0
      }
      return total
    },
    getStockEmployeeReport() {
      var data = {
        stock_id: this.data.id,
      }
      this.$store.state.isLoading = true
      instance_stock_employee.getStockEmployeeReport(data).then(res => {
        this.stocks = res.data.data.stock;
        this.stock_employees = res.data.data.stock_employees;
        this.$store.state.isLoading = false

        setTimeout(function () {
          window.print();
        },3000)

      }).catch(err => {

        this.$store.state.isLoading = false
      });
    },
    amountLessThan20() {
      var data = {
        id: this.data_id,
        amount: this.amount,
        type: this.amount,
        purchase_order_type: this.purchase_order_type,
      }
      instance_purchase_order.show(data.id).then(res => {
        if (res && res.data) {
          this.data = res.data.data
          if (this.data.is_vat === 'Y') {
            this.data.is_vat = true;
          } else {
            this.data.is_vat = false;
          }
          this.purchase_order_details = res.data.purchase_order_details

          this.data.purchase_order_details = res.data.purchase_order_details
          if (this.data.purchase_order_details.length < 7) {
            this.length_for = (7 - this.data.purchase_order_details.length);
            this.purchase_order_details_length = this.data.purchase_order_details.length;
          }
        }
      }).catch(err => {

      })
      // axios.get('{{url('admin/get_purchase_order').'/'.$data->id}}'+ '?amount=' + this.amount + '&type=' + this.type + '&purchase_order_type='+ this.purchase_order_type)

    }
  },
  payment() {
    instance_payment_type.get().then(res => {
      if (res && res.data) {
        this.payment_types = res.data
      }
    })
  }
}
</script>

<style scoped>

@page {
  size: A4;
  margin: 0;
  height: 100vh;
}

html, body {
  padding: 0;
  size: A4;
  margin: 0;
  color: black;
  background-color: white;
  height: 100vh;
}

table {
  color: black;
  font-family: "Garuda";
  font-size: 12px;
}

/*.table-striped > tbody > tr:nth-child(2n+1)  {*/
/*    background-color: #f2f2f2*/
/*}*/
.table-striped tbody > tr:nth-child(2n+1) {
  background-color: #b7b4b1;
}

.row {
  margin-right: -15px;
  margin-left: -15px;
}

.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col-lg-12 {
  width: 100%;
}

.container {

  height: 100vh;
  padding: 50px 70px 0;
}

@media print {
  html, body {
    width: 210mm;
    height: 297mm;
  }

  img-print_envelope {
    width: 100px;
    height: 100px;
  }

  table {
    font-family: "Garuda";
    width: 100%;
    /*font-size: 11pt;*/
  }
  /*.table-striped > tbody > tr:nth-child(2n+1)  {*/
  /*    background-color: #f2f2f2*/
  /*}*/
  .table-striped tbody > tr:nth-child(2n+1) {
    background-color: #b7b4b1;
  }

  .row {
    margin-right: -15px;
    margin-left: -15px;
  }

  .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
  }

  .col-lg-12 {
    width: 100%;
  }

  /* ... the rest of the rules ... */
}
</style>
